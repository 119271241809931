/* eslint-disable jsx-a11y/anchor-is-valid, no-useless-escape */
import * as React from "react";
import intlTelInput from 'intl-tel-input';
import intlTelUtils from 'intl-tel-input/build/js/utils';
import querystring from 'querystring';

import ReactGA from 'react-ga';
import axios from 'axios'
import swal from 'sweetalert';

import SEO from "../core/seo"
import SubmissionThankYou from './SubmissionThankYou';

import { validateUrl, validateEmail, validatePhone} from '../util/validate';
import { getDevice } from '../util/getDevice';
import { handleSelect, handleFiles, bucketName, clickSelectFile } from '../util/upload';
import { detectIsMobile, getUTM, alphaOnly} from '../util/helper';

import logo from '../images/FA_LOGO.png';

const windowGlobal = typeof window !== 'undefined' && window

export default class IndexPage extends React.PureComponent {

  state = {
    submissionsThankyou: false,

    isMobile: false,
    eventAction: "Design4",

    url: "",
    first_name: "",
    last_name: "",
    email: "",
    country: "",

    countries: [],
    utm: {
      utmCampaign: null,
      utmSource: null,
      utmMedium: null,
      utmContent: null
    },

  };

  intil;

  componentDidMount = () => {
    var input = document.querySelector("#phone");
    this.intil = intlTelInput(input, {
      separateDialCode: true,
      utilsScript: intlTelUtils
    });

    this.getCountry();
    this.setState({
      isMobile: detectIsMobile(),
      utm: getUTM()
    });

    ReactGA.ga('send', 'event', this.state.eventAction, 'pageView', { nonInteraction: true });
  }
  
  getCountry = () => {
    axios.get(`${process.env.GATSBY_RIFF_URL}/api/public/countries/all`)
      .then(response => {
        this.setState({
          countries: response.data
        });
      });
  }

  updateField(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  }

  
  onSubmit = (event) => {
    event.preventDefault();
    
    let count = 0;
    let errors = false;
    let error_string = '';
    let age = document.querySelector("#age").checked;
    let agreeTerms = document.querySelector("#agreeTerms").checked;
    let newsletterSignUp = document.querySelector("#newsletterSignUp").checked;
    let videoUrl = document.querySelector("#url").value.replace(/\s/g,'');
    let firstName = document.querySelector('#first_name').value.trim();
    let lastName = document.querySelector('#last_name').value.trim();
    let emailInput = document.querySelector('#email').value.trim();
    let phoneNumber = document.querySelector('#phone').value.trim();
    let country = document.querySelector('#country').value;

    if (this.state.isMobile) {
      if (windowGlobal.FA_Upload.type === 'upload' && !windowGlobal.FA_Upload.fileName) {
        ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Footage not provided');
        error_string += 'Video footage must be uploaded before submitting.\n';
        errors = true;
      } else if (windowGlobal.FA_Upload.type === 'url' && videoUrl === "") {
        ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'URL is blank');
        error_string += 'Video URL is required.\n';
        errors = true;
      } else if (windowGlobal.FA_Upload.type === null) {
        ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Type is blank');
        error_string += "Select 'Upload Video' or 'Provide Link'.\n";
        errors = true;
      }
    } else {
      if (videoUrl === '') {
        ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'URL is blank');
        error_string += 'Video URL is required.\n';
        errors = true;
      }
    }


    if (!validateUrl(videoUrl, count) && videoUrl.trim().length > 0) {
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Invalid Url');
      error_string += 'Video URL must be a valid URL.\n';
      errors = true;
    }
    if (firstName === '') {
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'First Name is blank');
      error_string += 'First Name field is required.\n';
      errors = true;
    }
    if (firstName.length < 2) {
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Invalid First Name');
      error_string += 'Please enter a valid first name.\n';
      errors = true;
    }
    if (lastName === '') {
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Last Name is blank');
      error_string += 'Last Name field is required.\n';
      errors = true;
    }
    if (lastName.length < 2) {
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Invalid Last Name');
      error_string += 'Please enter a valid last name.\n';
      errors = true;
    }
    if (emailInput === '') {
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Email is blank');
      error_string += 'Email field is required.\n';
      errors = true;
    }
    if (!validateEmail(emailInput)) {
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Invalid Email');
      error_string += 'Please enter a valid email address.\n';
      errors = true;
    }
    if(phoneNumber === ''){
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Phone Number is blank');
      error_string += 'Phone Number field is required.\n';
      errors = true;
    }
    if(!validatePhone(phoneNumber)){
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Invalid Phone Number');
      error_string += 'Please enter a valid phone number. Only Numbers, +,-,(,) and space are allowed.\n';
      errors = true;
    }
    if(phoneNumber !== '' && validatePhone(phoneNumber)){
      var number = phoneNumber.replace(/[^0-9.]/g, "");
      if(number.length < 8) {
        ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Invalid Phone Number');
          error_string += 'Please enter a valid phone number.\n';
          errors = true;
      }
    }
    if (!age) {
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Age not checked');
      error_string += 'You have not warranted that you are 13 years of age or older.\n';
      errors = true;
    }
    if (!agreeTerms) {
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Terms not checked');
      error_string += 'Please indicate that you have read and agree to the Terms of Service and Terms of Submission.\n';
      errors = true;
    }

    if (country === '') {
      ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', 'Country is blank');
      error_string += 'Country field is required.\n';
      errors = true;
    }

    if(errors){
      swal({
        title: "Oops...",
        text: error_string,
        className: "sweet-center",
        icon: "error"
      });

      return false;
    } else {
      document.querySelector('#newSubmission').disabled = true;
      document.querySelector('.loader').classList.remove('d-none');
      let data = {
        "firstName": firstName,
        "lastName": lastName,
        "videoUrl": videoUrl,
        "email": emailInput,
        "countryCode": country,
        "bucketName": windowGlobal.FA_Upload.fileName ? bucketName : null,
        "fileKey": windowGlobal.FA_Upload.fileName,
        "phone": this.intil.getNumber(),
        "isEuCitizen": document.querySelector('#eu').checked,
        "acceptServiceTerms": agreeTerms,
        "acceptSubmissionTerms": agreeTerms,
        "thirteenYears": age,
        "newsletterSubscription": newsletterSignUp,
        "source": "failarmysubmission",
        "isOwner": document.querySelector('#is_owner').checked,
        "campaignCode": this.state.utm.utmCampaign !== null ? this.state.utm.utmCampaign.slice(0,255) : '',
        "campaignSource": this.state.utm.utmSource !== null ? this.state.utm.utmSource.slice(0,255) : '',
        "campaignMedium": this.state.utm.utmMedium !== null ? this.state.utm.utmMedium.slice(0,255) : '',
        "campaignContent": this.state.utm.utmContent !== null ? this.state.utm.utmContent.slice(0,255) : '',
        "campaignPlatform": getDevice().device
      };

      let submissionType = windowGlobal.FA_Upload.fileName ? 'File Upload' : 'Video Url';
      
      if (newsletterSignUp) {
        this.submitSubscribeFormSubmission({
          email_address: emailInput,
          status: "subscribed",
          merge_fields: {
            FNAME: firstName,
            LNAME: lastName,
            MMERGE7: this.intil.getNumber()
          },
          tags: ['SUBMISSION']
        }, document.querySelector('#subscribe-result'));

        ReactGA.ga('send', 'event', this.state.eventAction, 'Newsletter Sign Up', submissionType);
      }

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }

      axios.post(`${process.env.GATSBY_RIFF_URL}/api/public/submission`, querystring.stringify(data), config)
      .then(data => {
        ReactGA.ga('send', 'event', this.state.eventAction, 'SubmittedSuccessfully', submissionType);
        document.querySelector('#newSubmission').disabled = false;
        document.querySelector('.loader').classList.add('d-none');
        document.getElementById("failArmySubmissionForm").reset();
        this.setState({ submissionsThankyou: true });

        if (this.state.isMobile) {
          var methodSelect = document.getElementById('method-select');
          methodSelect.options[0].selected = true;
          handleSelect();
        }
      })
      .catch(error => {

        let errorMessage = "";

        if (error.response) { errorMessage = error.response.data.messages[0]; }
        else if (error.request) { errorMessage = error.request;  }
        else { errorMessage = error.message }

        let swalMessage = document.createElement("div");
        swalMessage.innerHTML = "<h4 style='text-align:left; font-size: 18px; font-weight: 400'>There was a problem with your submission. Here\'s why you might be receiving this message:</h4><ul><li style='text-align:left'>You might have already submitted your video to one of Jukin\'s other brands or submission websites. If this is the case, we\'re already reviewing it!</li><li style='text-align:left'>Someone else may have submitted your video. Have no fear; our research staff always finds and contacts a video\'s rightful owner.</li><li style='text-align:left'>You may be trying to submit a URL type that our system doesn\'t recognize.</li></ul><p style='text-align:left'>If you don\'t think your situation is described above, please email <a href='mailto:videosubmissions@jukinmedia.com'>videosubmissions@jukinmedia.com</a> for assistance.</p>";
        swal({
          title: "",
          content: swalMessage,
          className: "sweet-center",
          icon: "error"
        });
        document.querySelector('#newSubmission').disabled = false;
        document.querySelector('.loader').classList.add('d-none');
        ReactGA.ga('send', 'event', this.state.eventAction, 'SubmissionErrors', errorMessage);
        
      });
    }
  }

  submitSubscribeFormSubmission = (mailchimpData, $resultElement) => {
    var mailchimpUrl = '';
    var listID = ''
    if(document.location.hostname !== 'www.failarmy.com'){
        mailchimpUrl = 'https://j4x7fdvbfj.execute-api.us-west-2.amazonaws.com/dev/subscribe';
        listID = '7031aaaefe'
    } else{
        mailchimpUrl = 'https://j4x7fdvbfj.execute-api.us-west-2.amazonaws.com/prod/subscribe';
        listID = '23f90c56ed'
    }

    axios.post(mailchimpUrl, {
      mailchimpListID: listID,
      data: mailchimpData
    }).then(response => {
      if (response.data.result === "success") {
        let message = "For some reason we are unable to add you as a subscriber at this time. Please try again later.";
        if (response.data.msg.indexOf("already subscribed") !== -1) {
            message = "It looks like you are already subscribed.";
            ReactGA.ga('send', 'event', this.state.eventAction, 'Submission Newsletter', 'Already Subscribed');
        }
        if (response.data.msg.indexOf('fake or invalid') !== -1) {
            message = "Please enter a valid email address.";
            ReactGA.ga('send', 'event', this.state.eventAction, 'Submission Newsletter', 'Fake or Invalid');
        }
        $resultElement.innerHTML = message;
        
      } else {
        ReactGA.ga('send', 'event', this.state.eventAction, 'Submission Newsletter', 'Successfully Signed Up');
      }
    }).catch(error => {})

  }

  render() {
    return (
      <div className="container">
        <SEO title="Submit Form" />
        <form onSubmit={this.onSubmit } id="failArmySubmissionForm">
          <input type="hidden" id="source" name="source" value="failarmy" />

          { this.state.isMobile ? (
            
            <React.Fragment>
              <div className="row">
                <div className="col-12 selector">
                    <label className="sr-only" htmlFor="method-select"></label>
                    <select id="method-select" onChange={handleSelect}>
                      <option defaultValue="">Choose One...</option>
                      <option value="upload">Upload Video</option>
                      <option value="url">Provide Link</option>
                    </select>
                </div>
              </div>

              <div id="url-form" className="row d-none">
                <div className="col-12">
                  <label className="sr-only" htmlFor="url" />
                  <input type="text" id="url" placeholder="Copy & paste your video link here *" maxLength="1024" name="url" onChange={event => this.updateField(event)} />
                </div>
              </div>

              <div id="upload-form" className="row d-none">
                <div className="col-12">
                  <label htmlFor="url">Upload Your Video <span className="astrick">*</span></label>
                  <input className="d-none" type="file" id="upload" name="file-upload" accept="video/*" onChange={(event) => handleFiles(event.target.files)}/>
                  <button className="btn btn-xs btn-select-file" type="button" onClick={() => clickSelectFile()}>
                    Select File
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                    <div id="upload-message" className="text-center d-none">Upload Complete</div>
                    <div id="upload-progress" className="progress d-none">
                        <div className="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100">0%</div>
                    </div>
                </div>
              </div>
            </React.Fragment>

          ): (

            <div className="row">
              <div className="col-12">
                <label className="sr-only" htmlFor="url" />
                <input type="text" id="url" placeholder="Copy & paste your video link here *" maxLength="1024" name="url" onChange={event => this.updateField(event)} />
              </div>
            </div>

          )}
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="row">

                <div className="col-12">
                  <label className="sr-only" htmlFor="first_name" />
                  <input type="text" id="first_name" placeholder="First Name *" maxLength="56" name="firs_name" onKeyDown={event => alphaOnly(event)} onChange={event => this.updateField(event)}/>
                </div>

                <div className="col-12">
                  <label className="sr-only" htmlFor="last_name" />
                  <input type="text" id="last_name" placeholder="Last Name *" maxLength="56" name="last_name" onKeyDown={event => alphaOnly(event)} onChange={event => this.updateField(event)}/>
                </div>

                <div className="col-12">
                  <label className="sr-only" htmlFor="email" />
                  <input type="text" id="email" placeholder="Email Address *" maxLength="128" name="email" onChange={event => this.updateField(event)}/>
                </div>

                <div className="col-12 phone-container">
                  <label className="sr-only" htmlFor="phone" />
                  <input type="text" id="phone" maxLength="27" />
                </div>

                <div className="col-12 selector">
                  <select id="country" name="country" onChange={event => this.updateField(event)}>
                    <option value="" defaultValue className="colored">Please Select Country *</option>
                    {this.state.countries.map((item, index) => (
                      <option value={item.code} key={String(index)} className="colored">{ item.name }</option>
                    ))}
                  </select>
                </div>
              </div>

            </div>

            <div className="col-md-12 col-lg-6">
              <img className="fa-logo-desktop" src={logo} alt="Fail Armly" />
            </div>
          </div>

          <div className="row">
            <div className="col-12 checker">

              <div className="clearer" />
              <div className="checkbox">
                <input type="checkbox" id="is_owner" value="owner"/>
                <label htmlFor="is_owner">
                  <span>I was holding the camera to record this video! No one else helped me create the video.</span>
                </label>
              </div>

              <div className="clearer" />

              <div className="checkbox">
                <input type="checkbox" id="newsletterSignUp"/>
                <label htmlFor="newsletterSignUp">
                  <span>Sign me up for the newsletter. </span>
                </label>
              </div>

              <div className="clearer" />

              <div className="checkbox">
                <input type="checkbox" id="eu"/>
                <label htmlFor="eu">
                  <span>I am an EU citizen and/or reside in the EU. </span>
                </label>
              </div>

              <div className="clearer" />

              <div className="checkbox">
                <input type="checkbox" id="agreeTerms"/>
                <label htmlFor="agreeTerms">
                  <span>
                    I have read and agree to the
                    {' '}
                    <a className="underline" onClick={() => ReactGA.ga('send', 'event', 'Design4', 'Submission', 'Terms of Service')} href="https://www.failarmy.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a> and
                    {' '}
                    <a className="underline" onClick={() => ReactGA.ga('send', 'event', 'Design4', 'Submission', 'Terms of Submission')} href="https://www.failarmy.com/terms-of-submission" target="_blank" rel="noopener noreferrer">{' '}Terms of Submission</a>. <span className="astrick">*</span>
                  </span>
                </label>
              </div>

              <div className="clearer" />

              <div className="checkbox">
                <input type="checkbox" id="age"/>
                <label htmlFor="age">
                  <span>I am 13 years of age or older. <span className="astrick">*</span> </span>
                </label>
              </div>

              <button type="submit" className="fa-button black-button" id="newSubmission">
                Submit video
                <div className="loader d-none"/>
              </button>              

            </div>


            <div className="col-12">
              <p className="required">* Required</p>
            </div>

            <div className="col-12">
              <img className="fa-logo-mobile" src={logo} alt="Fail Armly" />
            </div>
          </div>

        </form>

        <SubmissionThankYou show={this.state.submissionsThankyou} onclickClose={() => this.setState({ submissionsThankyou: false })} />
      </div>
    )
  }
}