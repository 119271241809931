/* eslint-disable */

export const validateUrl = (url, count) => {
  var urlCheck = '://';
  let counter = wc(url, urlCheck, count);
  if(counter > 1) return false;
  else return url.match(/(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/);
}

export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const validatePhone = (phone) => {
  var re = /^[0-9\-\+\(\) ]*$/;
  return re.test(phone);
}

export const wc = (string, word, count) => {
  var length = typeof string === "string" && typeof word === "string" && word.length,
      loop = length,
      index = 0;
  while (loop) {
      index = string.indexOf(word, index);
      if (index !== -1) {
          count += 1;
          index += length;
      } else {
          loop = false;
      }
  }
  return count;
}