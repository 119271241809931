/* eslint-disable jsx-a11y/anchor-is-valid, no-useless-escape */

const windowGlobal = typeof window !== 'undefined' && window

export const getUrlParam = (name) => {
  const results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(windowGlobal.location.href);
  return results[1] || 0;
}

export const detectIsMobile = () => {
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) return true;
  return false;
}

export const getUTM = () => {
  let utm = {
    utmCampaign: null,
    utmSource: null,
    utmMedium: null,
    utmContent: null
  }

  if (windowGlobal.location.search.indexOf('utm_campaign') > -1) {
    utm.utmCampaign =  getUrlParam('utm_campaign')
  }

  if (windowGlobal.location.search.indexOf('utm_source') > -1) {
    utm.utmSource =  getUrlParam('utm_source')
  }

  if (windowGlobal.location.search.indexOf('utm_medium') > -1) {
    utm.utmMedium =  getUrlParam('utm_medium')
  }

  if (windowGlobal.location.search.indexOf('utm_content') > -1) {
    utm.utmContent =  getUrlParam('utm_content')
  }

  return utm;
}

export const alphaOnly = (event) => {
  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf("android") > -1;
  var key = event.keyCode;    
  if(!isAndroid) {      
    return ((key >= 65 && key <= 90) || key === 8 || key === 32 || key === 9 || key === 37 || key === 39 || key === 46 || key === 16);        
  }
}