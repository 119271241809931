import ReactGA from 'react-ga';
import swal from 'sweetalert';
import CryptoJSMD5 from 'crypto-js/md5';

const windowGlobal = typeof window !== 'undefined' && window

export const bucketName = 'jukinmedia-footage-submissions';

export const upload_prefix = process.env.GATSBY_UPLOAD_PREFIX;

export const handleSelect = () => {
  
  var methodType = document.querySelector('#method-select').value;

  if (methodType === 'url') {

    document.querySelector('#url-form').classList.remove('d-none');
    document.querySelector('#upload-form').classList.add('d-none');
    document.querySelector('#upload-message').classList.add('d-none');
    document.querySelector("#upload").value = '';
    windowGlobal.FA_Upload.fileName = null;
    windowGlobal.FA_Upload.type = 'url';

  } else if (methodType === 'upload') {
    
    document.querySelector('#upload-form').classList.remove('d-none');
    document.querySelector('#url-form').classList.add('d-none');
    document.querySelector('#url').value = "";
    windowGlobal.FA_Upload.type = 'upload';

  } else {

    document.querySelector('#upload-form').classList.add('d-none');
    document.querySelector('#url-form').classList.add('d-none');
    document.querySelector('#upload-message').classList.add('d-none');
    document.querySelector('#url').value = "";
    document.querySelector("#upload").value = "";
    windowGlobal.FA_Upload.fileName = null;
    windowGlobal.FA_Upload.type = null;
  }

  if (windowGlobal.FA_Upload.xhr) {
    windowGlobal.FA_Upload.xhr.abort();
  }
} 

export const uploadProgress = (p) => {
  var completePercentage = Math.round((p.loaded / p.total) * 100);

  const progress = document.querySelector("#upload-progress .progress-bar");
    progress.style.width = completePercentage + '%';
    progress.setAttribute("aria-value", completePercentage);
    progress.innerHTML = completePercentage + '%'
}

export const uploadComplete = () => {
  document.querySelector('#upload-message').classList.remove('d-none');
  document.querySelector('#upload-progress').classList.add('d-none');
  uploadProgress({loaded: 0, total: 100});
}

export const uploadFailed = () => {
  const uploadMessage = document.querySelector("#upload-message");
  uploadMessage.innerHTML = "The upload failed! Please try again.";
  uploadMessage.classList.add('danger');

}

export const uploadCanceled = () => {
  document.querySelector('#upload-progress').classList.add('d-none');
  uploadProgress({loaded: 0, total: 100});
}

export const uploadStart = () => {
  document.querySelector('#upload-progress').classList.remove('d-none');
  document.querySelector('#upload-form').classList.add('d-none');
}

export const calculatedFilename = (file) => {
    let name = upload_prefix;
    let date = new Date();
    name += date.getTime() - (date.getTimezoneOffset() * 60000);
    name += '-' + CryptoJSMD5(file.name);
    return name;
}

export const handleFiles = (fileList) => {
  var file = fileList[0];
    var extMatch = file.name.match(/([a-z0-9]{3,4})$/i);
    var ext = '';
    if (extMatch !== null) {
        ext = extMatch[0];
    }
    var validFileTypes = ['3g2', '3gp', 'asf', 'avi', 'dv', 'flv', 'mov', 'mp4', 'mpeg', 'mpg', 'qt', 'wmv', 'm4v'];
    if (file.type.indexOf('video') < 0 || validFileTypes.indexOf(ext.toLowerCase()) < 0) {
        ReactGA.ga('send', 'event', 'Design4', 'SubmissionErrors', 'FileExtensionError');
        swal("Oops...", 'Sorry, only video files are accepted.\n', "error");
        return;
    }
    if (file.size > 5368709120) {
        ReactGA.ga('send', 'event', 'Design4', 'SubmissionErrors', 'FileSizeError');
        swal("Oops...", 'Sorry, file size must be under 5GB.\n', "error");
        return;
    }

    var fileReader = new FileReader();
    var validFileSignature = false;
    fileReader.onloadend = function(e) {
      var arr = (new Uint8Array(e.target.result)).subarray(0, 4);
      var header = "";
      for(var j = 0; j < arr.length; j++) {
        header += arr[j].toString(16);
      }
      var approvedValues = ["00018", "00020", "0001c", "3026b275", "001ba", "00014", "52494646", "464c561"];
      if (approvedValues.indexOf(header) > -1) { validFileSignature = true }

      if(!validFileSignature){
        ReactGA.ga('send', 'event', 'Design4', 'SubmissionErrors', 'FileSignatureError');
        swal("Oops...", 'Sorry, only video files are accepted.\n', "error");
        return;
      }
      doUpload(file, ext);
    };
    fileReader.readAsArrayBuffer(file.slice(0,8));
}

export const doUpload = (file, ext) => {
  windowGlobal.FA_Upload.xhr = new XMLHttpRequest();
  let xhr = windowGlobal.FA_Upload.xhr;
  var baseUrl = "https://" + bucketName + ".s3-accelerate.dualstack.amazonaws.com/";
  windowGlobal.FA_Upload.fileName = calculatedFilename(file) + '.' + ext;
  var url = baseUrl + windowGlobal.FA_Upload.fileName;

  xhr.upload.addEventListener("progress", uploadProgress, false);
  xhr.addEventListener("load", uploadComplete, false);
  xhr.addEventListener("loadstart", uploadStart, false);
  xhr.addEventListener("error", uploadFailed, false);
  xhr.addEventListener("abort", uploadCanceled, false);
  xhr.open("PUT", url);
  xhr.setRequestHeader('Content-Type', 'application/octet-stream');
  xhr.setRequestHeader('x-amz-acl', 'bucket-owner-full-control');
  xhr.overrideMimeType(file.type);
  xhr.send(file);
}

export const clickSelectFile = () => {
  document.getElementById('upload').click()
}